
import {
  UploadOutlined,
  UserOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  TagOutlined,
  QqOutlined,
  WechatOutlined,
  AlipayOutlined
} from '@ant-design/icons-vue';
import EleCropperModal from 'ele-admin-pro/packages/ele-cropper-modal';
import {mapState} from "vuex"

export default {
  name: 'UserInfo',
  components: {
    UploadOutlined,
    UserOutlined,
    HomeOutlined,
    EnvironmentOutlined,
    TagOutlined,
    QqOutlined,
    WechatOutlined,
    AlipayOutlined,
    EleCropperModal
  },
  data() {
    return {
      // tab页选中
      active: 'info',
      // 表单数据
      form: {
        nickname: 'Jasmine',
        sex: '保密',
        email: 'eleadmin@eclouds.com',
        introduction: '',
        address: '',
        tellPre: '0752',
        tell: '',
        avatar: 'https://cdn.eleadmin.com/20200610/avatar.jpg'
      },
      // 表单验证规则
      rules: {
        nickname: [
          {required: true, message: '请输入昵称', type: 'string', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '请选择性别', type: 'string', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', type: 'string', trigger: 'blur'}
        ]
      },
      // 保存按钮loading
      loading: false,
      // 是否显示裁剪弹窗
      showCropper: false
    };
  },
  computed:{
      ...mapState("user",{
          userInfo:state => state.user
      })
  },
  methods: {
    /* 保存更改 */
    save() {
      this.$refs.form.validate().then(() => {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$message.success('保存成功');
        }, 800);
      }).catch(() => {
      });
    },
    /* 头像裁剪完成回调 */
    onCrop(res) {
      this.form.avatar = res;
      this.showCropper = false;
      this.$store.dispatch('user/setUser', Object.assign({}, this.$store.state.user.user, {
        avatar: res
      }));
    }
  }
}
